'use client'

import { useRecordError } from 'aws-rum-react'
import Error from 'next/error'
import { useEffect } from 'react'

export default function LivError({
  error,
}: Readonly<{
  error: Error & { digest?: string; code?: number }
}>) {
  const recordError = useRecordError()

  useEffect(() => {
    if (error !== null) {
      recordError(error)
    }
  }, [error, recordError])
  return (
    <Error
      statusCode={error?.code ?? 0}
      title={
        error?.digest ??
        'Un error inesperado ha ocurrido! Nuestro equipo de tecnología ha sido informado'
      }
    />
  )
}
